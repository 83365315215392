import { Component, type ReactNode } from 'react';
import classnames from 'classnames';
import scrollIntoView from 'scroll-into-view';
import { FormattedMessage } from 'dibs-react-intl';

import style from './ExpandableContent.scss';

import { ReadMoreButton } from './ReadMoreButton';

export const readMoreDefaultMessage = (
    <FormattedMessage id="abf.ExpandableContent.readMore" defaultMessage="Read More" />
);
export const readLessDefaultMessage = (
    <FormattedMessage id="abf.ExpandableContent.readLess" defaultMessage="Read Less" />
);

type Props = {
    initiallyExpanded: boolean;
    wrapperClassName?: string;
    className?: string;
    buttonClassName?: string;
    collapseHeight: number;
    onExpandEnd?: () => void;
    onToggleReadMore?: (arg: boolean) => void;
    readMoreMessage: ReactNode;
    readLessMessage: ReactNode;
    showReadMoreArrow: boolean;
    dataTn?: string;
    children: ReactNode;
};

type State = {
    hasReadMore: boolean;
    isExpanded: boolean;
};

class ExpandableContent extends Component<Props, State> {
    static defaultProps = {
        initiallyExpanded: false,
        collapseHeight: 120,
        readMoreMessage: readMoreDefaultMessage,
        readLessMessage: readLessDefaultMessage,
        showReadMoreArrow: true,
    };
    constructor(props: Props) {
        super(props);

        this.state = {
            isExpanded: props.initiallyExpanded,
            hasReadMore: props.collapseHeight === 0,
        };

        this.ref = {};

        this.toggleReadMore = this.toggleReadMore.bind(this);
    }
    componentDidMount(): void {
        this.setReadMore();
    }

    UNSAFE_componentWillReceiveProps(): void {
        this.setReadMore();
    }

    ref: { description?: { offsetHeight?: number }; top?: HTMLElement };

    getDescriptionHeight(): number {
        return this.ref.description?.offsetHeight || 0;
    }

    setReadMore(): void {
        // Check if the actual height of the description is greater than the collapseHeight.
        // If it is we want to show the "CONTINUE READING" text link.
        if (this.getDescriptionHeight() > this.props.collapseHeight) {
            this.setState({ hasReadMore: true });
        }
    }

    toggleReadMore(): void {
        const { isExpanded } = this.state;

        if (this.props.onToggleReadMore) {
            this.props.onToggleReadMore(isExpanded);
        }
        // When collapsing return the scroll to the top of the component.
        if (isExpanded) {
            scrollIntoView(this.ref.top, {
                time: 1000,
            });
        }

        this.setState({ isExpanded: !isExpanded });
    }

    render(): ReactNode {
        const {
            collapseHeight,
            className,
            buttonClassName,
            onExpandEnd,
            readMoreMessage,
            readLessMessage,
            dataTn,
            showReadMoreArrow,
        } = this.props;

        const { isExpanded, hasReadMore } = this.state;

        const contentClassname = classnames(style.content, className, {
            [style.expanded]: isExpanded,
        });
        const contentStyles = {
            maxHeight: isExpanded ? this.getDescriptionHeight() : collapseHeight,
        };

        const readMoreButtonProps = {
            className: buttonClassName,
            onClick: this.toggleReadMore,
            readMoreMessage,
            readLessMessage,
            isExpanded,
            showArrow: showReadMoreArrow,
        };

        return (
            <div className={this.props.wrapperClassName}>
                <span
                    ref={el => {
                        if (el) {
                            this.ref.top = el;
                        }
                    }}
                />

                <div
                    className={contentClassname}
                    style={contentStyles}
                    onTransitionEnd={onExpandEnd}
                    data-tn={dataTn}
                >
                    <div
                        ref={el => {
                            if (el) {
                                this.ref.description = el;
                            }
                        }}
                    >
                        {this.props.children}
                    </div>
                </div>

                {hasReadMore && <ReadMoreButton {...readMoreButtonProps} />}
            </div>
        );
    }
}

export { ExpandableContent };
