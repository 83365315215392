import { type FC, type ReactNode } from 'react';
import classnames from 'classnames';
import ArrowDown from 'dibs-icons/exports/legacy/ArrowDown';
import { Link } from 'dibs-elements/exports/Link';

import style from './ReadMoreButton.scss';

type Props = {
    isExpanded?: boolean;
    className?: string;
    onClick: () => void;
    readMoreMessage: ReactNode;
    readLessMessage: ReactNode;
    showArrow?: boolean;
};

export const ReadMoreButton: FC<Props> = props => {
    const {
        isExpanded,
        className,
        onClick,
        readLessMessage,
        readMoreMessage,
        showArrow = true,
    } = props;
    const arrowClassName = classnames(style.arrow, {
        [style.expanded]: isExpanded,
    });
    const dataTn = isExpanded ? 'read-less' : 'read-more';

    return (
        <Link onClick={onClick} dataTn={dataTn} className={classnames(style.button, className)}>
            {isExpanded ? readLessMessage : readMoreMessage}
            {showArrow && <ArrowDown className={arrowClassName} />}
        </Link>
    );
};
