import { trackEvent } from 'dibs-tracking';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { eventNameConstants } from 'dibs-tracking';

const sortValueMap = {
    newest: 'newest',
    'price-high': 'sort price high - low',
    'price-low': 'sort price low - high',
    relevance: 'relevance',
    featured: 'featured',
    popular: 'popular',
} as const;

export const trackSortEvent = (sortValue: string | null | undefined): void => {
    const sortType = sortValue && hasKey(sortValueMap, sortValue) ? sortValueMap[sortValue] : '';
    trackEvent({
        eventName: eventNameConstants.EVENT_RESULTS_PAGE_REFINEMENT,
        interaction_type: 'sort',
        step_interaction_name: sortType,
        category: 'results page refinement',
        action: 'sort',
        label: sortType,
        value: 0,
        isInteractiveEvent: true,
    });
};
