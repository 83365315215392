import { ClientCookieManager } from 'dibs-consented-storage';
import {
    UNAVAILABLE_ITEM_PRIORITY,
    UNAVAILABLE_ITEM_COOKIE_KEY,
    SHOW_UNAVAILABLE_ITEM_MODAL,
} from '../sbRespNotificationConstants';

/**
 * Notification Workflow
 *
 * See is the user has a cookie stored for an unavailable item.  If they do, execute the modal
 *
 */

export const sbRespNotificationUnavailableItemModalConfig = {
    name: 'unavailableItemNotification',
    priority: UNAVAILABLE_ITEM_PRIORITY,

    rule: () => {
        const itemName = ClientCookieManager.get(UNAVAILABLE_ITEM_COOKIE_KEY);

        if (itemName) {
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    },

    action: () => dispatch => {
        dispatch({
            type: SHOW_UNAVAILABLE_ITEM_MODAL,
        });
    },
};
