/**
 * @generated SignedSource<<ba56d6662a0de63e18229f1879419d74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedPageBreadcrumbs_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly breadcrumbs: ReadonlyArray<{
    readonly isLinkable: boolean | null;
    readonly path: string | null;
    readonly text: string | null;
  }> | null;
  readonly pageType: string | null;
  readonly seller: {
    readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
  } | null;
  readonly " $fragmentType": "SbSharedPageBreadcrumbs_itemSearch";
};
export type SbSharedPageBreadcrumbs_itemSearch$key = {
  readonly " $data"?: SbSharedPageBreadcrumbs_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedPageBreadcrumbs_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedPageBreadcrumbs_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "sellerBrandingHelpers_seller",
          "selections": [
            {
              "alias": "isRewarded",
              "args": [
                {
                  "kind": "Literal",
                  "name": "listNames",
                  "value": [
                    "SELLER_REWARDS_1",
                    "SELLER_REWARDS_2"
                  ]
                }
              ],
              "kind": "ScalarField",
              "name": "isAnchorListsMember",
              "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "breadcrumbs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLinkable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "6c07e902c3fb3e8ec0cfa6ccf9c1a782";

export default node;
