import serverVars from 'server-vars';
import { trackEvent, eventNameConstants } from 'dibs-tracking';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useFragment, graphql, useRelayEnvironment } from 'react-relay';
import {
    trackPageview,
    getSessionPageCount,
    trackUserEventItemPageView,
    trackUserEventPageView,
    getUserEventItem,
} from 'dibs-tracking';
import {
    trackEligibleForRerankEvent,
    getHasFiredEligibleForRerankEvent,
} from '../utils/tracking/searchBrowse/addPersonalizationTrackingData';
import { useSbSelector } from '../reducers/useSbSelector';
import { type SharedTrackingContainer_user$key } from './__generated__/SharedTrackingContainer_user.graphql';

type Props = {
    onTrackingFired: () => void;
    user: SharedTrackingContainer_user$key;
    gtmPageInfo?: Record<string, unknown>;
    additional?: Record<string, unknown> | null;
    visitCount?: number;
    skuId?: string;
    isMultiSku?: boolean;
    isEligibleForRerank?: boolean;
};

const userFragment = graphql`
    fragment SharedTrackingContainer_user on User {
        __typename
    }
`;

export const useSharedTracking = ({
    user: userRef,
    isEligibleForRerank,
    gtmPageInfo,
    isMultiSku,
    onTrackingFired = () => {},
    additional = null,
    visitCount = 0,
    skuId,
}: Props): void => {
    const environment = useRelayEnvironment();
    const user = useFragment(userFragment, userRef);
    const { isClient, fetchUser } = useSbSelector(({ relayVariables: { variables } }) => ({
        isClient: variables.isClient,
        fetchUser: variables.fetchUser,
    }));
    const [isTrackingInitialized, setIsTrackingInitialized] = useState(false);

    // If we're fetching user but have no user data, wait until that returns before firing tracking
    const waitingForUserData = fetchUser && !user;
    const waitingForSkuId = isMultiSku && !skuId;
    const hasGtmPageInfo = !!gtmPageInfo;

    const shouldFireTracking =
        !waitingForUserData &&
        !waitingForSkuId &&
        hasGtmPageInfo &&
        !isTrackingInitialized &&
        isClient;

    useEffect(() => {
        setIsTrackingInitialized(false);
    }, [visitCount, skuId]);

    useEffect(() => {
        // This is required to fire only when non-eligible for rerank guest users logs in and becomes eligible for rerank
        // cleanup with 'Personalized-Rerank` AB test removal
        if (isClient && isEligibleForRerank && !getHasFiredEligibleForRerankEvent()) {
            trackEligibleForRerankEvent();
        }
    }, [isClient, isEligibleForRerank]);

    useEffect(() => {
        if (isClient) {
            const seoAbTests = serverVars.get('seoAbTests');
            if (seoAbTests) {
                for (const testName in seoAbTests) {
                    if (Object.prototype.hasOwnProperty.call(seoAbTests, testName)) {
                        const variant = seoAbTests[testName];
                        // Hacking in A/B test tracking event for SEO test POC
                        // TODO: Remove once POC ends and SEO test type is added to framework
                        trackEvent({
                            category: 'a/b testing v3',
                            eventName: eventNameConstants.EVENT_AB_TESTING,
                            action: testName,
                            interaction_type: testName,
                            label: variant,
                            step_interaction_name: variant,
                            value: undefined,
                        });
                    }
                }
            }
        }
    }, [isClient]);

    const trackUserEventPageview = useCallback(
        (pageInfo?: Record<string, $TSFixMe>) => {
            if (skuId) {
                const userEventItem = getUserEventItem({
                    id: pageInfo?.additional?.itemId,
                    amount: pageInfo?.additional?.price,
                });
                if (userEventItem) {
                    trackUserEventItemPageView(environment, [userEventItem]);
                }
            } else {
                trackUserEventPageView(environment);
            }
        },
        [environment, skuId]
    );

    const fireTracking = useCallback(() => {
        let pageInfo = gtmPageInfo;
        if (additional) {
            const currentAdditional = gtmPageInfo?.additional || {};
            const sessionPageCount = getSessionPageCount();

            //This is required to match the value since when using pagination, sessionPageCount increment gets called after the event is fired
            const pageCountViewed = visitCount ? sessionPageCount + 1 : sessionPageCount;

            pageInfo = {
                ...gtmPageInfo,
                additional: {
                    ...currentAdditional,
                    ...additional,
                    pageCountViewed,
                },
            };
        }

        trackPageview(pageInfo);
        trackUserEventPageview(pageInfo);

        setIsTrackingInitialized(true);
        onTrackingFired();
    }, [additional, gtmPageInfo, trackUserEventPageview, onTrackingFired, visitCount]);

    useEffect(() => {
        if (shouldFireTracking) {
            fireTracking();
        }
    }, [shouldFireTracking, fireTracking]);
    return;
};

// Wrapper for class components
export const SharedTrackingContainer: FC<Props> = props => {
    useSharedTracking(props);
    return null;
};
